import { MAINTAIN_PERMISSION, REPRINT_PERMISSION, GROWERS_PADDOCKHARVESTWEIGHT, GROWERS_REJECTOBSERVATION, EDITSTATUS_PERMISSION, GROWERS_PADDOCKHARVESTWEIGHT_PADDOCKLIST } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { a as HARVEST_STATUS_COLORS, H as HARVEST_STATUS_TEXTS } from "./harvest.status.js";
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-paddock-transactions"
  }, [_c("h6", {
    staticClass: "h6 mb-0 mx-5 mt-5"
  }, [_c("a-icon", {
    staticClass: "mr-3",
    style: {
      color: "#15B982"
    },
    attrs: {
      "type": "import"
    }
  }), _vm._v("Harvest Weight ")], 1), _c("list-composer", {
    attrs: {
      "custom-url": _vm.customUrlHarvest,
      "hide-pagination": "",
      "has-active": false,
      "custom-padding-table": "pb-5 pr-5 pl-5 pt-0"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var record = _ref.record;
        return [_vm.paddockHarvestHasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Reverse Observation")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "undo",
            "size": "small",
            "type": "link",
            "disabled": _vm.isComplete
          },
          on: {
            "click": function($event) {
              return _vm.openReverse(record);
            }
          }
        })], 1) : _vm._e(), _vm.paddockHarvestHasRePrintPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Print")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "printer",
            "size": "small",
            "type": "link",
            "disabled": _vm.isComplete
          },
          on: {
            "click": function($event) {
              return _vm.print(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    attrs: {
      "data-index": "transDate",
      "title": "Transaction Date",
      "parse": _vm.parseDate
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "truck",
      "title": "Truck"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "grossWeight",
      "title": "Gross Weight"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "weightOut",
      "title": "Weight Out"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "weightObserved",
      "title": "Observed Weight"
    }
  })], 1)], 1);
};
var staticRenderFns$5 = [];
const __vue2_script$5 = {
  name: "ListHarvestTransaction",
  inject: ["apiUrl", "crud", "isComplete", "can"],
  data() {
    return {
      customUrlHarvest: `${this.apiUrl}/grower/contract-paddocks/${this.$route.params.id}/harvest-transaction`,
      isLastItem: []
    };
  },
  computed: {
    paddockHarvestHasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    },
    paddockHarvestHasRePrintPermission() {
      return this.can(REPRINT_PERMISSION);
    }
  },
  created() {
    this.crud.deleteFilter("IsInactive");
  },
  methods: {
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    },
    openReverse(record) {
      if (this.crud.getList().length === 1) {
        this.$confirm({
          class: "list__modal-confirm list__modal-reverse-transaction",
          icon: "undo",
          title: "No more transaction, Do you want to change the status",
          cancelText: "No",
          okText: "Yes, Reverse",
          okType: "danger",
          onOk: this.reverseItem(record)
        });
      } else {
        this.$confirm({
          class: "list__modal-confirm list__modal-reverse-transaction",
          icon: "undo",
          title: "Are you sure you want to reverse this observation?",
          cancelText: "No",
          okText: "Yes, Reverse",
          okType: "danger",
          onOk: this.reverseItem(record)
        });
      }
    },
    reverseItem(record) {
      return () => this.crud.destroyEntity(`${this.apiUrl}/grower/contract-paddocks/${this.$route.params.id}/reverse/${record.id}`, record.id).then(() => this.$emit("reverse", this.crud.getList().length === 0 ? true : false));
    },
    print(record) {
      this.$emit("print", record);
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, null, null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var ListHarvestTransaction$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-paddock-transaction"
  }, [_c("resource", {
    attrs: {
      "name": "grower.paddocks.harvest",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("list-harvest-transaction", {
    on: {
      "print": function($event) {
        return _vm.print($event);
      },
      "reverse": function($event) {
        return _vm.reverseItem($event);
      }
    }
  })], 1), _c("router-view")], 1);
};
var staticRenderFns$4 = [];
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  components: {
    ListHarvestTransaction: ListHarvestTransaction$1
  },
  props: {
    complete: {
      type: String,
      default: ""
    }
  },
  provide() {
    return {
      isComplete: this.complete === "C" ? true : false
    };
  },
  data() {
    return {
      apiUrl: apiUrl$2,
      page: GROWERS_PADDOCKHARVESTWEIGHT
    };
  },
  methods: {
    reverseItem(e) {
      this.$emit("reverse", e);
    },
    print(e) {
      this.$emit("print", e);
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var ListHarvestTransaction = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-paddock-transactions"
  }, [_c("h6", {
    staticClass: "h6 mb-0 mx-5"
  }, [_c("a-icon", {
    staticClass: "mr-3",
    style: {
      color: "#F5222D"
    },
    attrs: {
      "type": "export"
    }
  }), _vm._v("Reject Observations ")], 1), _c("list-composer", {
    attrs: {
      "custom-url": _vm.customUrlReject,
      "hide-pagination": "",
      "has-active": false,
      "custom-padding-table": "pb-5 pr-5 pl-5 pt-0"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var record = _ref.record;
        return [_vm.paddockHarvestHasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Reverse Observation")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "undo",
            "size": "small",
            "type": "link",
            "disabled": _vm.isComplete
          },
          on: {
            "click": function($event) {
              return _vm.openReverse(record);
            }
          }
        })], 1) : _vm._e(), _vm.paddockHarvestHasRePrintPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Print")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "printer",
            "size": "small",
            "type": "link",
            "disabled": _vm.isComplete
          },
          on: {
            "click": function($event) {
              return _vm.print(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    attrs: {
      "data-index": "transDate",
      "title": "Transaction Date",
      "parse": _vm.parseDate
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "attribute",
      "title": "Attribute"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "truck",
      "title": "Truck"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "grossWeight",
      "title": "Gross Weight"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "weightOut",
      "title": "Weight Out"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "weightObserved",
      "title": "Observed Weight"
    }
  })], 1)], 1);
};
var staticRenderFns$3 = [];
const __vue2_script$3 = {
  name: "ListRejectTransaction",
  inject: ["apiUrl", "crud", "isComplete", "can"],
  data() {
    return {
      customUrlReject: `${this.apiUrl}/grower/contract-paddocks/${this.$route.params.id}/reject-transaction`
    };
  },
  computed: {
    paddockHarvestHasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    },
    paddockHarvestHasRePrintPermission() {
      return this.can(REPRINT_PERMISSION);
    }
  },
  created() {
    this.crud.deleteFilter("IsInactive");
  },
  methods: {
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    },
    openReverse(record) {
      this.$confirm({
        class: "list__modal-confirm list__modal-reverse-transaction",
        icon: "undo",
        title: "Are you sure you want to reverse this observation?",
        cancelText: "No",
        okText: "Yes, Reverse",
        okType: "danger",
        onOk: this.reverseItem(record)
      });
    },
    reverseItem(record) {
      return () => this.crud.destroyEntity(`${this.apiUrl}/grower/contract-paddocks/${this.$route.params.id}/reverse/${record.id}`, record.id).then(() => this.$emit("reverse", false));
    },
    print(record) {
      this.$emit("print", record);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ListRejectTransaction$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-paddock-transaction"
  }, [_c("resource", {
    attrs: {
      "name": "grower.paddocks.reject",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("list-reject-transaction", {
    on: {
      "print": function($event) {
        return _vm.print($event);
      },
      "reverse": function($event) {
        return _vm.reverseItem($event);
      }
    }
  })], 1), _c("router-view")], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    ListRejectTransaction: ListRejectTransaction$1
  },
  props: {
    complete: {
      type: String,
      default: ""
    }
  },
  provide() {
    return {
      isComplete: this.complete === "C" ? true : false
    };
  },
  data() {
    return {
      apiUrl: apiUrl$1,
      page: GROWERS_REJECTOBSERVATION
    };
  },
  methods: {
    reverseItem(e) {
      this.$emit("reverse", e);
    },
    print(e) {
      this.$emit("print", e);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ListRejectTransaction = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-paddock-harvest"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _vm.entity ? _c("div", {
    staticClass: "header px-5 pt-3 pb-2 mb-0 d-flex justify-content-between align-items-center"
  }, [_c("div", {
    staticClass: "d-flex align-items-center flex-wrap w-100"
  }, [_c("a-icon", {
    staticClass: "arrow-left pr-3",
    attrs: {
      "type": "arrow-left"
    },
    on: {
      "click": function($event) {
        return _vm.onBack();
      }
    }
  }), _c("h5", {
    staticClass: "pr-3 m-0 h5"
  }, [_vm._v("Paddock - " + _vm._s(_vm.entity.paddockCode.trim()))]), _c("a-tag", {
    staticClass: "mr-auto",
    attrs: {
      "color": _vm.getStatusColor(_vm.entity.status)
    }
  }, [_vm._v(_vm._s(_vm.parseStatus(_vm.entity.status)))]), _vm.paddockHarvestHasMaintainPermission && _vm.activeKey === "2" ? _c("a-button", {
    staticClass: "px-3",
    on: {
      "click": _vm.openReverse
    }
  }, [_vm._v("Reverse All")]) : _vm._e()], 1)]) : _vm._e(), _c("a-tabs", {
    staticClass: "kh-tabs",
    model: {
      value: _vm.activeKey,
      callback: function($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, [_c("a-tab-pane", {
    key: "1",
    staticClass: "p-5",
    attrs: {
      "tab": "Observations"
    }
  }, [_c("div", {
    staticClass: "body"
  }, [_c("a-row", {
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 14
    }
  }, [_vm.entity ? _c("a-card", {
    style: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "d-flex flex-column",
    style: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "d-flex align-items-center justify-content-between mb-4"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("h4", {
    staticClass: "h4 m-0"
  }, [_vm._v("Paddock - " + _vm._s(_vm.entity.paddockCode.trim()))]), _c("p", {
    staticClass: "b7 mb-0"
  }, [_vm._v(_vm._s(_vm.entity.paddockRef))])]), _vm.paddockListHasEditStatusPermission ? _c("a-select", {
    staticClass: "status-filter",
    attrs: {
      "disabled": _vm.paddockStatus === "N" && _vm.entity.harvestWeight === 0
    },
    on: {
      "change": _vm.changeStatus
    },
    model: {
      value: _vm.paddockStatus,
      callback: function($$v) {
        _vm.paddockStatus = $$v;
      },
      expression: "paddockStatus"
    }
  }, _vm._l(_vm.statusFilter, function(option) {
    return _c("a-select-option", {
      key: option.value,
      attrs: {
        "value": option.value
      }
    }, [_c("span", {
      style: {
        color: option.color
      }
    }, [_vm._v(_vm._s(option.text))])]);
  }), 1) : _vm._e()], 1), _c("a-row", {
    attrs: {
      "gutter": [24, 24]
    }
  }, [_c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Grower",
      "disabled": true,
      "value": _vm.entity.growerCode + " " + _vm.entity.growerName,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Crop",
      "disabled": true,
      "value": _vm.entity.cropCode + " - " + _vm.entity.cropName,
      "span": 12
    }
  })], 1)], 1), _c("div", {
    staticClass: "mt-auto"
  }, [_vm._v(" Last modified by " + _vm._s(_vm.entity.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY HH:mm")) + " ")])], 1)]) : _vm._e()], 1), _c("a-col", {
    staticClass: "kh-edit-sample-info",
    attrs: {
      "lg": 24,
      "xl": 10
    }
  }, [_vm.entity ? _c("a-card", [_c("div", {
    staticClass: "d-flex align-items-center justify-content-between mb-5"
  }, [_c("h6", {
    staticClass: "h6"
  }, [_vm._v("Weight Summary")]), _vm.paddockHarvestHasMaintainPermission ? _c("a-button", {
    attrs: {
      "type": "primary",
      "disabled": _vm.paddockStatus === "C"
    },
    on: {
      "click": function($event) {
        return _vm.getWeight();
      }
    }
  }, [_vm._v("Get Harvest Weight")]) : _vm._e()], 1), _c("div", {
    staticClass: "d-flex align-items-center justify-content-between mb-3"
  }, [_c("p", {
    staticClass: "b7 m-0"
  }, [_vm._v("Harvest Weight")]), _c("h6", {
    staticClass: "h6"
  }, [_vm._v(" " + _vm._s(_vm.entity.harvestWeight) + " "), _c("span", {
    staticClass: "b6 gray-color"
  }, [_vm._v("KG")])])]), _c("div", {
    staticClass: "d-flex align-items-center justify-content-between mb-3"
  }, [_c("p", {
    staticClass: "b7 m-0"
  }, [_vm._v("Reject Observations")]), _c("h6", {
    staticClass: "h6"
  }, [_vm._v(" " + _vm._s(_vm.entity.rejectObservation) + " "), _c("span", {
    staticClass: "b6 gray-color"
  }, [_vm._v("KG")])])]), _c("div", {
    staticClass: "d-flex align-items-center justify-content-between pt-3 bt-gray"
  }, [_c("p", {
    staticClass: "h7 m-0"
  }, [_vm._v("Net Produce to be Graded")]), _c("h6", {
    staticClass: "h6"
  }, [_vm._v(" " + _vm._s(_vm.entity.harvestWeight - _vm.entity.rejectObservation) + " "), _c("span", {
    staticClass: "b6 gray-color"
  }, [_vm._v("KG")])])])]) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "list-samples"
  }, [_c("h6", {
    staticClass: "h6 mt-5 mb-3"
  }, [_vm._v("Reject Observations")]), _c("a-table", {
    staticClass: "mb-4",
    attrs: {
      "columns": _vm.columns,
      "data-source": _vm.data,
      "row-key": "id",
      "loading": !_vm.entity,
      "pagination": false,
      "bordered": ""
    },
    scopedSlots: _vm._u([_vm._l(["id", "sampleNumber", "sampleType", "lastSample", "operator", "grossWeight"], function(col) {
      return {
        key: col,
        fn: function(text) {
          return [_c("div", {
            key: col
          }, [[_vm._v(_vm._s(text))]], 2)];
        }
      };
    }), {
      key: "action",
      fn: function(text, record) {
        return [_c("div", {
          staticClass: "editable-row-action"
        }, [[_vm.rejectObservationHasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Detail")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "export",
            "size": "small",
            "type": "link",
            "disabled": _vm.entity.harvestWeight === 0 || _vm.paddockStatus === "C"
          },
          on: {
            "click": function($event) {
              return _vm.getWeight(record);
            }
          }
        })], 1) : _vm._e()]], 2)];
      }
    }], null, true)
  })], 1), _vm.visibleModal ? _c("a-modal", {
    attrs: {
      "centered": "",
      "ok-text": "Get Weight & Print Docket"
    },
    on: {
      "ok": _vm.submitModal
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function() {
        return [_c("a-row", {
          attrs: {
            "align": "middle",
            "gutter": 24,
            "type": "flex"
          }
        }, [_c("a-col", [_c("h6", {
          staticClass: "h6 m-0"
        }, [_vm._v(_vm._s(_vm.modalTitle))])]), _c("a-col", [_c("text-input", {
          attrs: {
            "hidden-field": "",
            "value": _vm.paddockModal.hiddenField
          },
          on: {
            "change": _vm.onHiddenFChange
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 1115981461),
    model: {
      value: _vm.visibleModal,
      callback: function($$v) {
        _vm.visibleModal = $$v;
      },
      expression: "visibleModal"
    }
  }, [_vm.paddockModal ? _c("div", {
    staticClass: "get-weight"
  }, [_c("a-row", {
    attrs: {
      "gutter": [24, 24]
    }
  }, [_c("a-col", {
    attrs: {
      "xs": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Gross Weight",
      "suffix": "KG"
    },
    on: {
      "change": _vm.onWeightObsChange
    }
  })], 1), _c("a-col", {
    staticStyle: {
      "height": "77px"
    },
    attrs: {
      "xs": 12
    }
  }), _c("a-col", {
    attrs: {
      "xs": 12
    }
  }, [_c("select-input", {
    attrs: {
      "label": "Truck",
      "data-source": _vm.commonTrucks,
      "source": "id",
      "source-label": "description"
    },
    on: {
      "change": _vm.onTrucksChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12
    }
  }, [_c("label", [_vm._v("Truck Weight")]), _c("a-input", {
    attrs: {
      "disabled": true,
      "suffix": "KG",
      "span": 24
    },
    model: {
      value: _vm.tareValue,
      callback: function($$v) {
        _vm.tareValue = $$v;
      },
      expression: "tareValue"
    }
  })], 1)], 1)], 1) : _vm._e()]) : _vm._e()], 1)]), _c("a-tab-pane", {
    key: "2",
    attrs: {
      "tab": `Transaction Details`
    }
  }, [_vm.entity ? _c("div", [!_vm.isUpdateHarvest ? _c("list-harvest-transaction", {
    attrs: {
      "complete": _vm.entity.status
    },
    on: {
      "reverse": function($event) {
        return _vm.fetchEntity($event);
      },
      "print": function($event) {
        return _vm.printWeight($event);
      }
    }
  }) : _vm._e(), !_vm.isUpdateReject ? _c("list-reject-transaction", {
    attrs: {
      "complete": _vm.entity.status
    },
    on: {
      "reverse": function($event) {
        return _vm.fetchEntity($event);
      },
      "print": function($event) {
        return _vm.printWeight($event);
      }
    }
  }) : _vm._e()], 1) : _vm._e()])], 1)], 1);
};
var staticRenderFns$1 = [];
var EditPaddockHarvest_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditPaddockHarvest",
  inject: ["apiUrl", "resourceProps", "canProps"],
  components: {
    ListHarvestTransaction,
    ListRejectTransaction
  },
  data() {
    const [
      paddockObservationsProps,
      commonTrucksProps,
      paddockChangeWeightProps,
      paddockPrintProps
    ] = this.resourceProps;
    const [paddockHarvestPermissions, paddockListPermissions, rejectObservationPermission] = this.canProps;
    return {
      paddockObservationsProps,
      commonTrucksProps,
      paddockChangeWeightProps,
      paddockPrintProps,
      customUrl: `${this.apiUrl}/grower`,
      entity: null,
      columns: [],
      weightObserved: 0,
      data: [],
      paddockId: null,
      paddockModal: null,
      modalTitle: null,
      visibleModal: false,
      itemsMenu: [
        {
          key: "harvestprocessing",
          title: "Harvest Processing",
          path: ""
        },
        {
          key: "paddock-harvest",
          title: "Paddock Harvest Weight ",
          path: "/watties-grower/paddock-harvest"
        }
      ],
      field: "Status",
      statusFilter: [
        {
          value: "N"
        },
        {
          value: "S"
        },
        {
          value: "C"
        }
      ],
      cacheStatus: null,
      tareValue: 0,
      isUpdateHarvest: false,
      isUpdateReject: false,
      paddockStatus: "",
      activeKey: "1",
      paddockListPermissions,
      paddockHarvestPermissions,
      rejectObservationPermission
    };
  },
  computed: {
    commonTrucks() {
      return this.commonTrucksProps.crud.getList();
    },
    paddockHarvestHasMaintainPermission() {
      return this.paddockHarvestPermissions.can(MAINTAIN_PERMISSION);
    },
    paddockListHasEditStatusPermission() {
      return this.paddockListPermissions.can(EDITSTATUS_PERMISSION);
    },
    rejectObservationHasMaintainPermission() {
      return this.rejectObservationPermission.can(MAINTAIN_PERMISSION);
    }
  },
  watch: {
    tareValue(newVal) {
      this.tareValue = newVal;
    },
    paddockStatus(newVal) {
      this.paddockStatus = newVal;
    }
  },
  async mounted() {
    this.paddockId = this.$route.params.id;
    await this.paddockObservationsProps.crud.fetchEntity(`${this.customUrl}/contract-paddocks/${this.paddockId}`), this.entity = this.paddockObservationsProps.crud.getEntity();
    if (this.entity.status === "N")
      this.onBack();
    this.data = this.entity.paddockObservations;
    this.commonTrucksProps.crud.fetchList(`${this.customUrl}/common/trucks?trucktypeId=1`, void 0, false);
    this.itemsMenu = [
      ...this.itemsMenu,
      {
        key: `${this.entity && this.entity.id}`,
        title: `Paddock - ${this.entity && this.entity.paddockCode}`,
        path: `/watties-grower/intake-samples/${this.entity && this.entity.id}`
      }
    ];
    this.createdStatusFilter();
    this.paddockStatus = this.entity.status;
    this.columns = [
      {
        title: "Observation ID",
        dataIndex: "id",
        width: 150,
        scopedSlots: { customRender: "id" }
      },
      {
        title: "Sample Type",
        dataIndex: "sampleType",
        width: 150,
        scopedSlots: { customRender: "sampleType" }
      },
      {
        title: "Attribute",
        dataIndex: "attributeCode",
        align: "center",
        width: 140,
        scopedSlots: { customRender: "attributeCode" }
      },
      {
        title: "Attribute Description",
        dataIndex: "attributeDescription",
        scopedSlots: { customRender: "attributeDescription" }
      },
      {
        title: "Weight Observed (KG)",
        dataIndex: "weightObserved",
        width: 160,
        scopedSlots: { customRender: "weightObserved" }
      },
      {
        title: "Action",
        dataIndex: "action",
        width: 90,
        align: "center",
        scopedSlots: { customRender: "action" }
      }
    ];
  },
  methods: {
    parseCropCode(value, record) {
      if (!value)
        return "";
      return `${value} ${record.cropName}`;
    },
    parseGrowerCode(value, record) {
      if (!value)
        return "";
      return `${value} - ${record.growerName}`;
    },
    getStatusColor(value) {
      return HARVEST_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return HARVEST_STATUS_TEXTS[value];
    },
    async fetchEntity(reverseAllItem = false) {
      if (reverseAllItem) {
        this.submitChangeStatus("N");
        return this.onBack();
      }
      await this.paddockObservationsProps.crud.fetchEntity(`${this.customUrl}/contract-paddocks/${this.paddockId}`), this.entity = this.paddockObservationsProps.crud.getEntity();
      this.data = this.entity.paddockObservations;
      this.paddockStatus = this.entity.status;
      this.isUpdateHarvest = false;
      this.isUpdateReject = false;
    },
    createdStatusFilter() {
      this.statusFilter.map((key) => {
        Object.assign(key, {
          text: HARVEST_STATUS_TEXTS[key.value],
          color: HARVEST_STATUS_COLORS[key.value]
        });
      });
    },
    changeStatus(e) {
      this.cacheStatus = this.entity.status;
      if (e === "N" && this.entity.harvestWeight !== 0) {
        this.$confirm({
          title: "Warning",
          content: "To change status to Not Started, please reverse all Harvest Weight and Reject Observation in Transaction Details tab.",
          icon: "exclamation-circle",
          cancelText: "Cancel",
          okText: "Direct to Transaction Details",
          onOk: () => {
            this.paddockStatus = this.cacheStatus;
            this.activeKey = "2";
          },
          onCancel: () => {
            this.paddockStatus = this.cacheStatus;
          }
        });
      } else
        this.submitChangeStatus(e);
    },
    submitChangeStatus(e) {
      let isNotStarted = e === "N" ? true : false;
      if (this.entity.status === e)
        return;
      this.paddockObservationsProps.crud.updateEntity("editForm", {
        resourceKey: "status",
        value: e
      });
      this.paddockObservationsProps.crud.submitEntity("replace", `${this.customUrl}/contract-paddocks/${this.entity.id}/status`).then(() => {
        if (isNotStarted)
          this.onBack();
        this.isUpdateReject = true;
        this.isUpdateHarvest = true;
        this.fetchEntity();
      }).catch(() => this.paddockStatus = this.cacheStatus);
    },
    storeValue(value, resourceKey, isPrint = null) {
      if (isPrint) {
        this.paddockPrintProps.crud.updateEntity("createForm", {
          resourceKey,
          value
        });
        return;
      }
      if (!resourceKey.includes(",")) {
        this.paddockChangeWeightProps.crud.updateEntity("editForm", {
          resourceKey,
          value
        });
        return;
      }
      const resourceKeys = resourceKey.split(",");
      resourceKeys.forEach((key) => this.paddockChangeWeightProps.crud.updateEntity("editForm", {
        resourceKey: key,
        value: value[key]
      }));
    },
    getWeight(record = null) {
      this.paddockModal = {};
      this.modalTitle = "Get Harvest Weight";
      this.paddockModal = Object.assign({}, this.entity);
      if (record) {
        this.modalTitle = "Get Reject Observations";
        this.paddockModal = Object.assign({}, record);
      }
      this.paddockModal = Object.assign(this.paddockModal, {
        tare: 0,
        weightObserved: null,
        truckId: null,
        observationId: record ? record.id : null,
        hiddenField: ""
      });
      this.visibleModal = true;
    },
    submitModal() {
      let resourceKey = "truckId,weightObserved,observationId,hiddenField";
      if (this.paddockModal.observationId)
        this.isUpdateReject = true;
      else
        this.isUpdateHarvest = true;
      this.storeValue(this.paddockModal, resourceKey);
      this.paddockChangeWeightProps.crud.submitEntity("replace", `${this.customUrl}/contract-paddocks/${this.entity.id}/transaction`).then((res) => {
        if (!this.paddockModal.observationId && this.entity.harvestWeight === 0 && this.entity.status === "N") {
          this.submitChangeStatus("S");
        } else
          this.fetchEntity();
        this.visibleModal = false;
        this.tareValue = 0;
        this.printWeight(res.data);
      });
    },
    onTrucksChange(e) {
      this.paddockModal.truckId = e;
      this.tareValue = this.commonTrucks.filter((v) => v.id === e)[0].tare;
      this.paddockModal.tare = this.tareValue;
    },
    onWeightObsChange(e) {
      if (e !== "") {
        let isNumber = /^\d+$/.test(e);
        if (!isNumber)
          e = this.weightObserved;
        else
          this.weightObserved = parseInt(e);
      } else
        this.weightObserved = e;
      this.paddockModal.weightObserved = this.weightObserved;
    },
    printWeight(data) {
      this.storeValue(!data.observationId ? "HarvestDocket" : "HarvestDocketObservation", "report", true);
      this.storeValue("grower", "reportType", true);
      this.storeValue({ TransID: data.id }, "reportParams", true);
      this.paddockPrintProps.crud.submitEntity("create", `${this.apiUrl}/identities/reports`).then((res) => window.open(res.data.reportViewerUri, "_blank"));
    },
    openReverse() {
      this.$confirm({
        class: "list__modal-confirm list__modal-reverse-transaction",
        icon: "undo",
        title: "Are you sure you want to reverse all observations?",
        cancelText: "No",
        okText: "Yes, Reverse All",
        okType: "danger",
        onOk: () => this.fetchEntity(true)
      });
    },
    onHiddenFChange(e) {
      this.paddockModal.hiddenField = e;
    },
    onBack() {
      this.$router.push(`/watties-grower/paddock-harvest`);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "de8387ba", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditPaddockHarvest = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-paddock-harvest-containers"
  }, [_c("resource", {
    attrs: {
      "name": ["grower.contract-paddocks", "grower.commmon.trucks", "grower.contract-paddocks.change-weight", "grower.contract-paddocks.print"],
      "api-url": _vm.apiUrl,
      "edit": _vm.EditPaddockHarvest,
      "redirect-route": ["/watties-grower/paddock-harvest", "", "", ""],
      "page": _vm.pages
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditPaddockHarvest",
  provide() {
    return {
      apiUrl
    };
  },
  data() {
    return {
      EditPaddockHarvest,
      apiUrl,
      pages: [
        GROWERS_PADDOCKHARVESTWEIGHT,
        GROWERS_PADDOCKHARVESTWEIGHT_PADDOCKLIST,
        GROWERS_REJECTOBSERVATION
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
